import React from "react";
import { getAuth } from "firebase/auth";
import { DynamicForm, DynamicFormActions } from "components";
import { useAuth, useUpdateProfile } from "hooks";
import * as Yup from "yup";

export const ChangeProfil = () => {
  const { user } = useAuth();
  const { mutateAsync: updateProfil } = useUpdateProfile();

  const handleOnSubmit = ({ firstName, lastName }) => {
    const currentUser = getAuth().currentUser;
    if (currentUser) {
      return updateProfil({
        user: currentUser,
        displayName: `${firstName} ${lastName}`,
      });
    }
  };
  return (
    <DynamicForm
      initialValue={user}
      fields={{
        firstName: {
          label: "Prénom",
        },
        lastName: {
          label: "Nom",
        },
        email: {
          label: "Email",
          disabled: true,
        },
      }}
      actions={
        <DynamicFormActions
          sx={{ flexDirection: "row" }}
          submitTitle="Valider"
        />
      }
      onSubmit={handleOnSubmit}
      validationSchema={Yup.object({
        firstName: Yup.string().required("ce champ est requis"),
        lastName: Yup.string().required("Ce champ est requis"),
        email: Yup.string()
          .email("Adresse email incorrect")
          .required("Ce champ est requis"),
      })}
    />
  );
};

export default ChangeProfil;
