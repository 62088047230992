import { useCreateMutation } from "hooks/queries";
import React from "react";
import Forms, { FormsProps } from "./forms";

export const Add: React.FC<FormsProps> = ({onSubmit, ...props}) => {
  const { mutateAsync: create } = useCreateMutation(props.name);

  const handleOnSubmit = (values) => {
    if(onSubmit){
      return onSubmit(values);
    }
    return create({ ...values, created: new Date().getTime() });
  };
  return <Forms  onSubmit={handleOnSubmit} {...props} />;
};
