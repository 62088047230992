import React, { useCallback } from "react";
import { fieldToSelect } from "formik-mui";
import { Field } from "formik";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import ListSubheader from "@mui/material/ListSubheader";
import { makeStyles } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export const CustomSelect = (props: any) => {
  const classes = useStyles();

  const {
    form: { errors, submitCount },
    field: { name },
  } = props;

  const fieldProps = fieldToSelect(props);

  const renderValue = useCallback(
    (selected: string[]) => (
      <div className={classes.chips}>
        {(selected || []).map((value, label) => (
          <Chip
            color={fieldProps.disabled ? "default" : "primary"}
            key={value}
            label={
              props.options.find((option) => option.value === value)?.label ??
              value
            }
            className={classes.chip}
          />
        ))}
      </div>
    ),
    [classes, fieldProps.disabled]
  );
  const canShowError = errors[name] && !props.disabled && submitCount > 0;
  return (
    <FormControl
      variant="outlined"
      className={props.className}
      error={canShowError}
      sx={{width: "100%"}}
    >
      <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      <Select
        {...fieldProps}
        
        renderValue={props.multiple ? (renderValue as any) : undefined}
      >
        {(props.options || []).map((option: any) =>
          option.isTitle ? (
            <ListSubheader>{option.label}</ListSubheader>
          ) : (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          )
        )}
      </Select>
      {canShowError && errors[name] && (
        <FormHelperText>{errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export const SelectField = (props: any) => (
  <Field {...props} component={CustomSelect} />
);
export default SelectField;
