import React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { deepOrange } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Apps from "@mui/icons-material/Apps";
import UserIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import { useAuth, useLogout, useRoutes } from "hooks";
import { useQueryClient } from "react-query";

const useStyles = makeStyles((theme: any) => ({
  avatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    width: 35,
    height: 35,

    cursor: "pointer",
  },
  img: {
    width: 40,
    height: 40,
  },
  bigAvatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  container: {
    width: 250,
  },
  infos: {
    display: "flex",
    padding: theme.spacing(2),
    alignItems: "center",
  },
  names: {
    flex: 1,
    marginLeft: theme.spacing(2),
    textTransform: "capitalize",
    fontSize: 18,
  },
  form: {
    marginTop: theme.spacing(2),
  },
  action: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
}));

export const AuthBox: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { paths, navigate } = useRoutes();
  // const dispatch = useDispatch();
  //const darkMode = useSelector((state: State) => state.settings.darkMode);
  const { user, isLoggedIn } = useAuth();
  const { mutateAsync: logout } = useLogout();
  const queryClient = useQueryClient();

  const displayName = !user?.firstName && !user?.lastName ? user?.email : user.firstName + " " + user.lastName

  const initialName = displayName && displayName.charAt(0).toUpperCase();

  const navigateToLogin = navigate(paths.login.path);
  const navigateToProfil = navigate(paths.profil.path);
  const navigateToAdminDashboard = navigate(paths.admin.path);

  const handleOnLogout = async () => {
    try {
      await logout();
      queryClient.clear();
      navigateToLogin();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (menuCallback) => () => {
    handleClose();
    menuCallback();
  };

  if (!isLoggedIn) {
    return (
      <Button color="secondary" variant="outlined" onClick={navigateToLogin}>
        Connexion
      </Button>
    );
  }

  return (
    <div>
      <div onClick={handleClick}>
        <div className={classes.avatar}>
          <Avatar sx={{width: 35, height: 35}} className={classes.img}>{initialName}</Avatar>
        </div>
      </div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper className={classes.container}>
          {isLoggedIn && (
            <div>
              <div className={classes.infos}>
                <div className={classes.bigAvatar}>
                  <Avatar className={classes.img}>{initialName}</Avatar>
                </div>
                <Typography className={classes.names}>
                {displayName}
                </Typography>
              </div>
              <Divider />
            </div>
          )}
          <List component="nav">
            <ListItem
              button
              onClick={handleMenuClick(navigateToAdminDashboard)}
            >
              <ListItemIcon>
                <Apps />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button onClick={handleMenuClick(navigateToProfil)}>
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <ListItemText primary="Profil" />
            </ListItem>
            <ListItem button onClick={handleOnLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Déconnexion" />
            </ListItem>
          </List>
        </Paper>
      </Popover>
    </div>
  );
};

export default AuthBox;
