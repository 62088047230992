import React, { useCallback } from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Delete";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useRoutes, useDialog } from "hooks";
import { AddFab, AlertDialog } from "components";
import { useCollectionQuery, useDeleteMutation } from "hooks/queries";
import { useQueryClient } from "react-query";
import { CollectionParams, WhereCondition } from "api/api.typing";

export type ListProps = {
  name: string;
  columns: GridColDef[];
  conditions?: CollectionParams
};

export const List: React.FC<ListProps> = ({ name, columns, conditions }) => {
  const { data = [], isLoading, error } = useCollectionQuery(name, {
    orderBy: { key: "created", order: "desc" },
    ...(conditions ?? {})
  });

  console.log('conditions', conditions); 
  const [
    { open, data: selected },
    openDialog,
    closeDialog,
  ] = useDialog<string>();

  const { mutateAsync: remove } = useDeleteMutation(name, selected ?? "rr");

  const { paths, navigate } = useRoutes();
  const navigateToForms = navigate(`/admin/${name}/new`);
  const queryClient = useQueryClient();

  const handleOnEdit = (id) => {
    navigate(`/admin/${name}/${id}`)();
  };
  const handleOnConfirm = async () => {
    try {
      closeDialog();
      queryClient.setQueryData(
        [name],
        data.filter((d) => (d as any).id != selected)
      );
      remove();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <AlertDialog
        title="Supprimer"
        description="Etes vous sur de vouloir supprimer?"
        cancelTitle="Annuler"
        confirmTitle="Supprimer"
        open={open}
        onClose={closeDialog}
        onConfirm={handleOnConfirm}
      />
      <DataGrid
        rows={data}
        columns={[
          ...columns,
          {
            field: "Actions",
            headerName: "Actions",
            renderCell: (column) => (
              <div>
                <IconButton onClick={() => handleOnEdit(column.id)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => openDialog(column.id.toString())}
                  color="error"
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            ),
          },
        ]}
        pageSize={20}
        loading={isLoading}
        error={error}
        autoHeight
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
      />

      <AddFab color="secondary" onClick={navigateToForms}>
        <AddIcon />
      </AddFab>
    </div>
  );
};

export default List;
