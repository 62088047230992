import { Button, Typography } from "@mui/material";
import { AuthContainer, Layout } from "components";
import { useRoutes } from "hooks";
import React from "react";

export const NotFound = () => {
  const { navigate, paths } = useRoutes();
  const navigateToHome = navigate(paths.home.path);
  return (
    <Layout>
      <AuthContainer title="Page non trouvée">
        <Typography variant="subtitle1">
          Désolé, la page que vous avez demandée est introuvable{" "}
        </Typography>
        <Button sx={{ marginTop: 2 }} onClick={navigateToHome}>Aller à la page d'accueil</Button>
      </AuthContainer>
    </Layout>
  );
};

export default NotFound;
