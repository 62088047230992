export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role?: UserRole;
  section?: string;
};

export type UserMetadata = {
  favourites: { [key: string]: number };
  played: { [key: string]: { time: number; video: string } };
};

export enum UserRole {
  administrator = "admin",
  section= "section"
}