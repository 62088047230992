import { Box, Button, Typography } from "@mui/material";
import React from "react";

type DescriptionProps = {
  title: string;
  actionTitle: string;
  subtitle?: string;
  onClick: () => void;
};

export const Description: React.FC<DescriptionProps> = ({
  title,
  actionTitle,
  subtitle,
  onClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{ maxWidth: 500 }}
        variant="h4"
        color="white"
        textAlign="center"
        gutterBottom
      >
        {title}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        sx={{ minWidth: 100 }}
        onClick={onClick}
      >
        {actionTitle}
      </Button>

      <Typography
        sx={{ marginTop: "5px" }}
        variant="subtitle2"
        color="white"
        align="center"
        gutterBottom
      >
        {subtitle}
      </Typography>
    </Box>
  );
};
