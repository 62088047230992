import {
  useFirestoreCollectionMutation,
  useFirestoreDocumentDeletion,
  useFirestoreDocumentMutation,
} from "@react-query-firebase/firestore";
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getFirestore,
} from "firebase/firestore";
import { useMutation } from "react-query";

export const generateId = (key: string) => {
  return doc(collection(getFirestore(), key)).id;
};

type DocMutationParams = {
  merge?: boolean;
};

export const useDocMutation = <T>(
  key: string,
  subkey: string,
  params?: DocMutationParams
) => {
  const query = doc(getFirestore(), key, subkey) as DocumentReference<T>;
  return useFirestoreDocumentMutation(query, params);
};

export const useCreateMutation = <T>(key: string) => {
  const query = collection(getFirestore(), key) as CollectionReference<T>;
  return useFirestoreCollectionMutation(query);
};

export const useDeleteMutation = (key: string, subkey: string) => {
  const ref = doc(collection(getFirestore(), key), subkey);
  return useFirestoreDocumentDeletion(ref);
};
