import {
  getAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  User as FirebaseUser,
} from "firebase/auth";
import {
  useAuthUser,
  useAuthSignInWithEmailAndPassword,
  useAuthCreateUserWithEmailAndPassword,
  useAuthSendPasswordResetEmail,
  useAuthSignInWithPopup,
  useAuthUpdateProfile,
  useAuthUpdatePassword,
  useAuthConfirmPasswordReset,
  useAuthSignOut,
} from "@react-query-firebase/auth";

export type SocialProvider = "facebook" | "google";

export const getProvider = (type: SocialProvider) => {
  if (type === "facebook") {
    return new FacebookAuthProvider();
  }

  if (type === "google") {
    return new GoogleAuthProvider();
  }
};

type Callbacks = {
  onError?: (error: { message?: string; code?: string }) => void;
  onSuccess?: (data: any) => void;
};

export const useLogin = (callbacks?: Callbacks) => {
  return useAuthSignInWithEmailAndPassword(getAuth(), callbacks);
};

export const useForgetPassword = (callbacks?: Callbacks) => {
  return useAuthSendPasswordResetEmail(getAuth(), callbacks);
};

export const useContinueWithSocial = (callbacks?: Callbacks) => {
  return useAuthSignInWithPopup(getAuth(), callbacks);
};

export const useRegister = (callbacks?: Callbacks) => {
  return useAuthCreateUserWithEmailAndPassword(getAuth(), callbacks);
};

export const useUpdateProfile = (callbacks?: Callbacks) => {
  return useAuthUpdateProfile(callbacks);
};

export const useUpdatePassword = (callbacks?: Callbacks) => {
  return useAuthUpdatePassword(callbacks);
};

export const useResetPassword = (callbacks?: Callbacks) => {
  return useAuthConfirmPasswordReset(getAuth(), callbacks);
};

export const useLogout = (callbacks?: Callbacks) => {
  return useAuthSignOut(getAuth(), callbacks);
};

const mapUser = (user: FirebaseUser | null) => {
  if (user) {
    try {
      const { firstName = "", lastName = "" } = JSON.parse(
        user.displayName ?? ""
      );
      return { id: user.uid, email: user.email ?? "", firstName, lastName };
    } catch {
      return {
        id: user.uid,
        email: user.email ?? "",
        firstName: user.displayName,
        lastName: "",
      };
    }
  }
  return null;
};

export const useAuth = () => {
  const query = useAuthUser(["user"], getAuth());
  const user = mapUser(query.data ?? null);
  const isLoggedIn = !!user;
 
  const loading = !query.isSuccess 
   
  return {
    ...query,
    user,
    isLoggedIn,
    loading,
  };
};
