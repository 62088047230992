import { TextField } from "formik-mui";
import { Field } from "formik";
import Typography from "@mui/material/Typography";
import SelectField from "./select-field";
import RadioField from "./radio-field";

export const DynamicField = (props: any) => {
  if (props.type === "section") {
    return (
      <Typography variant="h6" component="h2" color="textPrimary">
        {props.label}
      </Typography>
    );
  }
  if (props.type === "custom") {
    return props.component;
  }
  if (props.type === "select") {
    return <SelectField {...props} />;
  }
  if (props.type === "radio") {
    return <RadioField {...props} />;
  }
  return <Field {...props} variant="outlined" component={TextField} />;
};

export default DynamicField;
