import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useCollectionQuery } from "hooks";

export const Messages = () => {
  const { data = [], isLoading, error } = useCollectionQuery<any>(
    "messages",
    undefined,
    { enabled: true }
  );

  return (
    <div>
      <DataGrid
        rows={data as any}
        columns={[
          { field: "id", headerName: "ID", width: 200 },
          { field: "firstName", headerName: "Prénom", width: 250 },
          { field: "lastName", headerName: "Nom", width: 300 },
          { field: "message", headerName: "Message", width: 300 },
        ]}
        pageSize={20}
        loading={isLoading}
        error={error}
        autoHeight
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
      />
    </div>
  );
};

export default Messages;
