import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

import { AuthBox } from "../auth-box";
import { useRoutes } from "../../hooks";
import { Box, useMediaQuery, useTheme } from "@mui/material";

const useStyles = makeStyles((theme: any) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  right: {
    display: "flex",
    alignItems: "center",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      // backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: 600,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  image: {
    width: 40,
    height: 40,
    cursor: "pointer",
  },
}));

type HeaderProps = {
  transparent?: boolean;
  enableMenu?: boolean;
  onMenuClick?: () => void;
};

export function Header({ transparent, enableMenu, onMenuClick }: HeaderProps) {
  const theme = useTheme();
  const classes = useStyles();
  const { navigate, paths } = useRoutes();
  const [showSearch, setShowSearch] = useState(false);
  const navigateToHome = navigate(paths.home.path);
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (matches) {
      setShowSearch(false);
    }
  }, [matches]);
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: transparent && !showSearch ? "rgba(255, 255, 255,0)" : undefined,
      }}
      elevation={transparent && !showSearch ? 0 : 4}
    >
      <Toolbar className={classes.toolbar}>
        {((!showSearch && matches) || !matches) && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {matches && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={onMenuClick}
              >
                <MenuIcon />
              </IconButton>
            )}

            <img
              className={classes.image}
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="logo"
              onClick={navigateToHome}
            />
          </Box>
        )}

        {showSearch && (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              paddingX: {md:3},
            }}
          >
          </Box>
        )}

        {((!showSearch && matches) || !matches) && (
          <Box sx={{ display: "flex", gap: 2, color: "white" }}>
            {!showSearch && (
              <IconButton color="inherit" onClick={() => setShowSearch(true)}>
                <SearchIcon />
              </IconButton>
            )}
            <AuthBox />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
