import React from "react";
import { BrowserRouter } from "react-router-dom";
import {
  ThemeProvider,
  createTheme as createMuiTheme,
} from "@mui/material/styles";
import "./App.css";
import Root from "./root";
import { initializeApp } from "firebase/app";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "firebase/auth";
import "firebase/firestore";
import { LocalPayment } from "components/local-payment/local-payment";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";

const firebaseConfig = {
  apiKey: "AIzaSyDZR4YB_RqTXkRSD7zw0SAthwl4zYRVt-g",
  authDomain: "digital-axon-87422.firebaseapp.com",
  databaseURL: "https://digital-axon-87422.firebaseio.com",
  projectId: "digital-axon-87422",
  storageBucket: "digital-axon-87422.appspot.com",
  messagingSenderId: "454436018581",
  appId: "1:454436018581:web:543ded2a6bb5ce5d0dfe9c",
};

initializeApp(firebaseConfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 24 * 60 * 60 * 1000,
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

/*persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});*/

const createTheme = (darkMode: boolean) =>
  createMuiTheme({
    palette: {
      //mode:"dark",
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#1b5e20",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#fff",
      },
      secondary: {
        //light: '#0066ff',
        main: "#fbc02d",
        // dark: will be calculated from palette.secondary.main,
        //contrastText: '#ffcc00',
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      //contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: ["gotham", "Roboto", "Arial", "sans-serif"].join(","),
    },
    /*overrides: {
      MuiButton: {
        root: {
          borderRadius: 50,
          height: 40,
        },
      },
    },*/
  });

function App() {
  const darkMode = false;
  const theme: any = createTheme(darkMode);
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
            <Root />
        </ThemeProvider>
      </BrowserRouter>
      {process.env.NODE_ENV == "development" && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
}

export default App;
