import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { FeedBack, FeedBackProps } from "../feedback";
import DynamicFormActions, {
  DynamicFormActionsProps,
} from "./dynamic-form-actions";
import DynamicFormBody, { DynamicFormBodyProps } from "./dynamic-form-body";
import { Box } from "@mui/material";

type DynamicFormProps = DynamicFormBodyProps &
  DynamicFormActionsProps & {
    validationSchema?: any;
    children?: any;
    feedBack?: FeedBackProps;
    successFeedBack?: Omit<FeedBackProps, "type">;
    errorFeedBack?: Omit<FeedBackProps, "type">;
    initialValue?: any;
    header?: React.ReactNode;
    actions?: React.ReactNode;
    onSubmit: (data?: any) => void;
  };

export const DynamicForm: React.FC<DynamicFormProps> = ({
  fields,
  validationSchema,
  feedBack,
  successFeedBack,
  errorFeedBack,
  initialValue,
  disabled,
  header,
  actions,
  submitTitle,
  cancelTitle,
  onSubmit,
  onChange,
  onCancel,
}) => {
  const [
    internalFeedBack,
    setInternalFeedback,
  ] = useState<FeedBackProps | null>(null);

  const handleOnSubmit = async (values) => {
    try {
      await onSubmit?.(values);
      if (successFeedBack) {
        setInternalFeedback({ type: "success", ...successFeedBack });
      }
    } catch (error) {
      const feedback = errorFeedBack
        ? errorFeedBack
        : ((error as unknown) as { code: string; message: string });
      setInternalFeedback({ type: "error", ...feedback });
    }
  };

  useEffect(() => {
    if (feedBack) {
      setInternalFeedback(feedBack);
    }
  }, [feedBack]);

  return (
    <div>
      {!!internalFeedBack && (
        <Box sx={{ marginBottom: 3 }}>
          <FeedBack {...internalFeedBack} />
        </Box>
      )}
      {header && <Box sx={{ marginBottom: 3 }}>{header}</Box>}
      <Formik
        initialValues={initialValue || {}}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <Form>
          <DynamicFormBody
            onChange={onChange}
            fields={fields}
            disabled={disabled}
          />
          <Box sx={{ marginTop: 3 }}>
            {actions ? (
              actions
            ) : (
              <DynamicFormActions
                submitTitle={submitTitle}
                cancelTitle={cancelTitle}
                onCancel={onCancel}
              />
            )}
          </Box>
        </Form>
      </Formik>
    </div>
  );
};

export default DynamicForm;
