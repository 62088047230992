import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  getDocs,
  getFirestore,
  limit,
  query,
  Query,
  QueryDocumentSnapshot,
  SnapshotOptions,
  where,
} from "firebase/firestore";
import {
  useFirestoreQueryData,
  useFirestoreDocumentData,
} from "@react-query-firebase/firestore";
import { CollectionParams, QueryParams } from "api/api.typing";
import { useQuery, QueryOptions } from "react-query";

const getListConverter = <T>() => ({
  toFirestore(data): DocumentData {
    console.log("to firestore");
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): T {
    const id = snapshot.id;
    const data = snapshot.data(options)!;
    return ({ id, ...data } as unknown) as T;
  },
});

const getList = <T>(key: string, params?: CollectionParams) => {
  let myCollection = collection(getFirestore(), key) as Query<T>;
  if (params?.where?.length) {
    params.where.forEach(([field, condition, value]) => {
      myCollection = query(myCollection, where(field, condition, value));
    });
  }
  if (params?.max) {
    myCollection = query(myCollection, limit(params.max));
  }

  myCollection = myCollection.withConverter(getListConverter<T>());
  return myCollection;
};

const getDocuments = async <T>(key: string, params?: CollectionParams) => {
  const query = getList<T>(key, params);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((d) => d.data());
};

export const useCollectionQuery = <T>(
  key: string,
  params?: CollectionParams,
  queryParams?: QueryParams
) => {
  return useQuery<T[]>(
    queryParams?.key ?? [key],
    () => getDocuments<T>(key, params),
    queryParams
  );
};

export const useDocQuery = <T>(
  key: string,
  subkey: string,
  params?: QueryParams
) => {
  const query = doc(getFirestore(), key, subkey) as DocumentReference<T>;
  return useFirestoreDocumentData<T>(
    [key, subkey],
    query,
    {
      subscribe: params?.subscribe,
    },
    params
  );
};
