import React from "react";
import { Box, Button, SxProps } from "@mui/material";
import { SubmitButton } from "components";
import { useFormikContext } from "formik";

export type DynamicFormActionsProps = {
  submitTitle?: string;
  cancelTitle?: string;
  sx?: SxProps;
  onCancel?: () => void;
};

export const DynamicFormActions: React.FC<DynamicFormActionsProps> = ({
  submitTitle = "",
  cancelTitle,
  sx,
  onCancel,
}) => {
  const { isSubmitting, isValid } = useFormikContext();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, ...sx }}>
      <SubmitButton
        color="primary"
        title={submitTitle}
        variant="contained"
        disabled={!isValid || isSubmitting}
        isSubmitting={isSubmitting}
      />
      {cancelTitle && (
        <Button color="primary" onClick={onCancel}>
          {cancelTitle}
        </Button>
      )}
    </Box>
  );
};

export default DynamicFormActions;
