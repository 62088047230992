import { FormControl, FormControlLabel, FormLabel, Radio } from "@mui/material";
import { useFormikContext, Field } from "formik";
import { RadioGroup } from "formik-mui";
import React from "react";

type LabelValue = { label: string; value: string };
type RadioFieldProps = {
  label: string;
  name: string;
  options: LabelValue[];
};

const RadioField: React.FC<RadioFieldProps> = ({ name, label, options }) => {
  const { isSubmitting } = useFormikContext();
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Field component={RadioGroup} name={name}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio disabled={isSubmitting} />}
            label={option.label}
            disabled={isSubmitting}
          />
        ))}
      </Field>
    </FormControl>
  );
};

export default RadioField;
