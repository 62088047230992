import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useRoutes } from "hooks";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
  container: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 120,
    height: 120,
    marginBottom: theme.spacing(2),
    img: {
      width: "100%",
      Height: "100%",
    },
  },
  content: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
}));

type AuthContainerProps = {
  title: string;
  children?: React.ReactNode;
};

export const AuthContainer = ({ children, title }: AuthContainerProps) => {
  const { navigate, paths } = useRoutes();
  const navigateToHome = navigate(paths.home.path);
  const classes = useStyles();
  return (
    <Container
      maxWidth="sm"
      sx={{
        marginTop: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Link to="/">
        <img
          className={classes.image}
          src={`${process.env.PUBLIC_URL}/logo.png`}
          onClick={navigateToHome}
          alt="logo"
        />
      </Link>

      <Typography variant="h4" component="h2" align="center" gutterBottom>
        {title}
      </Typography>
      {children}
    </Container>
  );
};

export default AuthContainer;
