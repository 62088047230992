import React, { useState } from "react";
import * as Yup from "yup";
import Link from "@mui/material/Link";
import {
  DynamicForm,
  Social,
  AuthContainer,
  Layout,
  FeedBackProps,
} from "components";
import { useLogin, useRoutes } from "hooks";

export const Login: React.FC = () => {
  const { paths, navigate } = useRoutes();
  const navigateToRegister = navigate(paths.register.path);
  const navigateToHome = navigate(paths.members.path);
  const navigateToForgetPassword = navigate(paths.forgetPassword.path);
  const [error, setError] = useState<FeedBackProps>();

  const { mutateAsync: login } = useLogin({
    onSuccess: navigateToHome,
  });

  const handleOnSubmit = async ({ email, password }) => {
    await login({ email, password });
  };

  return (
    <Layout>
      <AuthContainer title="Connexion">
        <DynamicForm
          header={<Social onSuccess={navigateToHome} onError={setError} />}
          initialValue={{ email: "", password: "" }}
          fields={{
            email: {
              label: "Email",
              type: "text",
            },
            password: {
              label: "Mot de passe",
              type: "password",
            },
            reset: {
              label: "Mot de passe oublié",
              type: "custom",
              component: (
                <Link
                  sx={{ cursor: "pointer" }}
                  tabIndex={-1}
                  onClick={navigateToForgetPassword}
                >
                  Mot de passe oublié ?
                </Link>
              ),
            },
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Adresse email incorrect")
              .required("Ce champ est requis"),
            password: Yup.string().required("Ce champ est requis"),
          })}
          feedBack={error}
          submitTitle="Se connecter"
          cancelTitle="Créer un nouveau compte"
          onSubmit={handleOnSubmit}
          onCancel={navigateToRegister}
        />
      </AuthContainer>
    </Layout>
  );
};

export default Login;
