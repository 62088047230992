import React from "react";
import { Box, SxProps } from "@mui/material";

export const Layout: React.FC<{ sx?: SxProps }> = ({ children, sx = {} }) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          paddingX: { xs: 2, md: 6 },
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          boxSizing: "border-box",
          gap: 6,
          ...sx,
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default Layout;
