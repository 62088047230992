import React from "react";
import { styled } from "@mui/material";
import { useRoutes } from "hooks";

const Image = styled("img")(() => ({
  width: 40,
  height: 40,
}));

export const Logo = () => {
  const { paths, navigate } = useRoutes();
  const navigateToHome = navigate(paths.home.path);
  return (
    <Image
      src={`${process.env.PUBLIC_URL}/logo.png`}
      alt="logo"
      onClick={navigateToHome}
    />
  );
};

export default Logo;
