import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import DynamicField from "./dynamic-field";

export type FieldType = {
  name?: string;
  type?:
    | "text"
    | "password"
    | "email"
    | "date"
    | "select"
    | "radio"
    | "switch"
    | "number"
    | "file"
    | "section"
    | "input-range"
    | "autocomplete"
    | "checkbox"
    | "time"
    | "custom";
  label: string;
  initialValue?: string | number | Date | boolean | any[];
  required?: boolean;
  disabled?: boolean;
  grid?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  options?: any[];
  multiline?: boolean;
  rows?: number;
  onChange?: Function;
  multiple?: boolean;
  value?: any;
  component?: React.ReactNode;
  placeholder?: string;
};

export type DynamicFormBodyProps = {
  onChange?: (data: any) => void;
  disabled?: boolean;
  fields: { [key: string]: FieldType };
};

export const DynamicFormBody: React.FC<DynamicFormBodyProps> = ({
  onChange,
  fields,
  disabled,
}) => {
  const form = useFormikContext();

  useEffect(() => {
    if (onChange) {
      onChange(form);
    }
  }, [form, onChange]);

  return (
    <Grid container spacing={3}>
      {Object.keys(fields).map((fieldName) => (
        <Grid
          item
          xs={12}
          sm={fields[fieldName].grid}
          sx={{ display: "flex" }}
          key={fieldName}
        >
          <DynamicField
            {...fields[fieldName]}
            name={fieldName}
            sx={{flex: 1}}
            disabled={disabled || fields[fieldName]?.disabled}
          />
        </Grid>
      ))}
    </Grid>
  );
};
export default DynamicFormBody;
