import { Box, styled, SxProps } from "@mui/material";

import React from "react";

type ImageProps = {
  name: string;
  alt?: string;
  sx?: SxProps
};

const Container = styled(Box)((sx) => ({
  width: "100%",
  paddingBottom: "56.25%",
  overflow: "hidden",
  cursor: "pointer",
  position: "relative",
  backgroundColor: "rgba(0,0,0,0.2)"
}));

const RootImage = styled("img")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
}));

export const Image: React.FC<ImageProps> = ({ name, alt, sx }) => {
  return (
    <Container sx={sx}>
      <RootImage
        src={process.env.REACT_APP_THUMBNAIL_URL?.replace(":image", name)}
        alt={alt}
      />
    </Container>
  );
};
