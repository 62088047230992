import React from "react";
import { DynamicForm, DynamicFormActions } from "components";
import { useLogin, useUpdatePassword } from "hooks";
import * as Yup from "yup";
import { getAuth } from "firebase/auth";

export const ChangePassword = () => {
  const { mutateAsync: login } = useLogin();
  const { mutateAsync: updatePassword } = useUpdatePassword();

  const handleOnSubmit = async ({ password, newPassword }) => {
    const user = getAuth().currentUser;
    if (user && user.email) {
      await login({ email: user.email, password });
      await updatePassword({ user, newPassword });
    }
  };

  return (
    <DynamicForm
      initialValue={{ password: "", newPassword: "", confirmPassword: "" }}
      fields={{
        password: {
          label: "Mot de passe",
          type: "password",
        },
        newPassword: {
          label: "Nouveau mot de passe",
          type: "password",
        },
        confirmPassword: {
          label: "Confirmation du mot de passe",
          type: "password",
        },
      }}
      actions={
        <DynamicFormActions
          sx={{ flexDirection: "row" }}
          submitTitle="Valider"
        />
      }
      onSubmit={handleOnSubmit}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(6, "6 charactères minimum")
          .required("Ce champ est requis"),
        newPassword: Yup.string()
          .min(6, "6 charactères minimum")
          .required("Ce champ est requis"),
        confirmPassword: Yup.string()
          .oneOf([
            Yup.ref("newPassword"),
            "Les mots de pass ne se correspondent pas",
          ])
          .required("Ce champ est required"),
      })}
      submitTitle="Valider"
    />
  );
};

export default ChangePassword;
