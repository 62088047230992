import React from "react";

import { Box, Button, styled, Typography } from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  // background: `url(https://focus.telerama.fr/2022/04/29/0/0/5920/3947/1000/0/60/0/2de71b3_696637358-2032700-foot-110.jpg)`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const Overlay = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent:"center",
  width: "100vw",
  height: "100vh",
  background: `linear-gradient(
          360deg,
          rgba(27, 94, 32, 1) 0%,
          rgba(0, 0, 0, .3) 100%
        )`,
}));

export const CarousselImage: React.FC<{ image: string }> = ({
  image,
  children,
}) => {
  return (
    <Container
      sx={{
        backgroundImage: `url(${image})`,
      }}
    >
      <Overlay>{children}</Overlay>
    </Container>
  );
};

export default CarousselImage;
