import * as yup from "yup";
import { Crud } from "components";
import { useCollectionQuery } from "hooks";
import { Gender, Section } from "types/business";
import { useRoles } from "hooks/useRoles";

export const MembersCrud = () => {
  const { data: sections = [] } = useCollectionQuery<Section>('sections');
  const { isSection, section } = useRoles();

  console.log('isSection', isSection)
  return (
    <Crud
      name="members"
      initialValues={{
        firstname: "",
        lastname: "",
        profession: "",
        identifiantNumber: "",
        section: "",
        region: "",
      }}
      fields={{
        gender: {
          label: "Civilé",
          type: "select",
          options: [
            {
              label: Gender.man.toString(),
              value: Gender.man
            },
            {
              label: Gender.woman.toString(),
              value: Gender.woman
            }
          ]
        },
        firstname: {
          label: "Prénom",
        },
        lastname: {
          label: "Nom",
        },
        profession: {
          label: "Profession",
        },
        identifiantNumber: {
          label: "N° CNI/Passport",
        },
        section: {
          label: "Section/Dahira",
          type: "select",
          options: sections.map((section) => ({
            label: section.name,
            value: section.id,
          })),
        },
      }}
      validationSchema={yup.object().shape({
        firstname: yup.string().required("Ce champ est requis"),
        lastname: yup.string().required("Ce champ est requis"),
        profession: yup.string().required("Ce champ est requis"),
        identifiantNumber: yup.string().required("Ce champ est requis"),
      })}
      columns={[
        { field: "id", headerName: "ID", width: 200 },
        { field: "firstname", headerName: "Prénom", width: 250 },
        { field: "lastname", headerName: "lastname", width: 250 },
        { field: "profession", headerName: "Profession" },
      ]}
      conditions={isSection ? { where: [['section', '==', section ?? '']] } : undefined}
    />
  );
};

export default MembersCrud;
