import React from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme:any) => ({
  loading: {
    marginRight: theme.spacing(1),
  },
}));

interface SubmitBtnProps extends ButtonProps {
  title: string;
  isSubmitting?: boolean;
}

export const SubmitButton = ({
  type = "submit",
  title,
  isSubmitting = false,

  ...props
}: SubmitBtnProps) => {
  const classes = useStyles();
  return (
    <Button {...props} type={type}>
      {isSubmitting && (
        <CircularProgress
          size={20}
          color="inherit"
          className={classes.loading}
        />
      )}
      {title}
    </Button>
  );
};

export default SubmitButton;
