import React, { useState, useCallback } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Header, AdminSidebar, BreadCrumb, PrivateRoute } from "components";
import { useRoutes } from "hooks";
import { paths } from "routes";
import MembersCrud from "./MembersCrud";
import SectionsCrud from "./SectionsCrud";
import { Messages } from "./messages";
import UsersCrud from "./UsersCrud";
import { useRoles } from "hooks/useRoles";

const useStyles = makeStyles((theme: any) => ({
  root: {
    background: theme.palette.background.default,
    display: "flex",
    minHeight: "100vh",
  },
  toolbar: theme.mixins.toolbar,
  container: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  content: {
    minHeight: 100,
    background: theme.palette.background.paper,
    margin: `${theme.spacing(2)}px 0`,
    padding: theme.spacing(2),
  },
  infos: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  title: {
    color: theme.palette.text.primary,
    padding: 16
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
}));

export const Admin = () => {
  const classes = useStyles();
  const [mobileOpened, setMobileOpen] = useState(false);
  const { currentPath } = useRoutes();

  const { isAdmin } = useRoles();

  const handleMobileSidebarToggle = useCallback(() => {
    setMobileOpen((opened) => !opened);
  }, [setMobileOpen]);

  return (
    <div className={classes.root}>
      <Header enableMenu={true} onMenuClick={handleMobileSidebarToggle} />
      <AdminSidebar
        mobileOpened={mobileOpened}
        onClose={handleMobileSidebarToggle}
      />
      <main className={classes.container}>
        <div className={classes.toolbar} />
        <div className={classes.infos}>
          <div className={classes.left}>
            <Typography variant="h5" className={classes.title}>
              {currentPath?.label || "Formulaire"}
            </Typography>
          </div>
          <BreadCrumb />
        </div>
        <div className={classes.content}>
          <Routes>
          <Route path={"sections/*"} element={
              <PrivateRoute required={!isAdmin} navigateTo={paths.admin.path}>
                 <SectionsCrud />
              </PrivateRoute>
            } />
            <Route path={"members/*"} element={
                <MembersCrud />
            } />
            <Route path={"administrators/*"} element={
              <PrivateRoute required={!isAdmin} navigateTo={paths.admin.path}>
                <UsersCrud />
              </PrivateRoute>
            } />
            <Route path={"messages"} element={<Messages />} />
            <Route index element={<div>ouloulou</div>} />
          </Routes>
        </div>
      </main>
    </div>
  );
};
