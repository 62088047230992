import { useCollectionQuery, useMenuRoutes, useRoutes } from "hooks";
import React from "react";
import { Menu, Route, paths } from "routes";
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
} from "@mui/material";

const NavigationButton = styled(Button)(({ theme }) => ({
  my: 2,
  marginRight: 3,
  fontWeight: "bold",
  marginBottom: 0,
  color: "white",
  height: 64,
  display: "block",
  borderRadius: 0,
  borderBottom: `2px solid transparent`,
  "&:hover": {
    color: theme.palette.secondary.main,
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
}));

const NavigationItem: React.FC<{
  item: Route;
  subItems: Route[];
  onClick: (path: string) => void;
}> = ({ item, subItems, onClick }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const hasSubItems = !!subItems.length;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  const handleSubItemClick = (event, path) => {
    handleClose(event);
    onClick(path);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  return (
    <div>
      <NavigationButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={() => (hasSubItems ? handleToggle() : onClick?.(item.path))}
      >
        {item.label}
      </NavigationButton>
      {!!subItems?.length && (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {subItems.map((subItem) => (
                      <MenuItem
                        key={subItem.path}
                        onClick={(event) =>
                          handleSubItemClick(event, subItem.path)
                        }
                      >
                        {subItem.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </div>
  );
};

export const NavigationHorizontal = () => {
  const { navigate } = useRoutes();
  const menus: Menu[] = useMenuRoutes();

  const handleOnClick = (path) => {
    navigate(path)();
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: { xs: "none", md: "flex" },
        boxSizing: "border-box",
        border: "none",
      }}
    >
      {menus.map((menu) => (
        <NavigationItem
          key={menu.item.path}
          item={menu.item}
          subItems={menu.subItems}
          onClick={handleOnClick}
        />
      ))}
    </Box>
  );
};
