import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { getProvider, SocialProvider, useContinueWithSocial } from "hooks";

const FacebookButton = styled(Button)(() => ({
  flex: 1,
  backgroundColor: "#3b5998",
  "&:hover": {
    backgroundColor: "#3b5998",
  },
}));

const GoogleButton = styled(Button)(() => ({
  flex: 1,
  backgroundColor: "#DB4437",
  "&:hover": {
    backgroundColor: "#DB4437",
  },
}));

type SocialProps = {
  onSuccess?: () => void;
  onError?: (error?: { code?: string; message?: string }) => void;
};

export function Social({ onSuccess, onError }: SocialProps) {
  const { mutate: continueWithSocial } = useContinueWithSocial({
    onSuccess,
    onError: (error) => onError?.(error),
  });

  const loginWithSocial = (type: SocialProvider) => {
    const provider = getProvider(type);
    if (provider) {
      continueWithSocial({ provider });
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <FacebookButton
        variant="contained"
        color="primary"
        disableElevation
        onClick={() => loginWithSocial("facebook")}
      >
        Facebook
      </FacebookButton>
      <Box sx={(theme) => ({ width: theme.spacing(1) })} />
      <GoogleButton
        variant="contained"
        color="primary"
        disableElevation
        onClick={() => loginWithSocial("google")}
      >
        Google
      </GoogleButton>
    </Box>
  );
}
export default Social;
