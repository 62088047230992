import React from "react";
import * as Yup from "yup";
import { DynamicForm, AuthContainer, Layout } from "components";
import { useForgetPassword, useRoutes } from "hooks";

const successFeedBack = { code: "auth/reset-password-success" };

export const ForgetPassword: React.FC = () => {
  const { paths, navigate } = useRoutes();
  const navigateToRegister = navigate(paths.login.path);

  const { mutate: forgetPassword } = useForgetPassword();

  const handleOnSubmit = ({ email }) => {
    return forgetPassword({ email });
  };

  return (
    <Layout>
      <AuthContainer title="Mot de passe oublié">
        <DynamicForm
          initialValue={{ email: "" }}
          fields={{
            email: {
              label: "Email",
              type: "text",
            },
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Adresse email incorrect")
              .required("Ce champ est requis"),
          })}
          successFeedBack={successFeedBack}
          submitTitle="Valider"
          cancelTitle=" Se connecter"
          onSubmit={handleOnSubmit}
          onCancel={navigateToRegister}
        />
      </AuthContainer>
    </Layout>
  );
};

export default ForgetPassword;
