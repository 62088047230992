import React from "react";
import {
  Box,
  Button,
  styled,
} from "@mui/material";
import { AuthContainer, CarousselImage, Description, Footer } from "components";
import { useRoutes } from "hooks";


const Login = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

export const Home = () => {
  const { navigate, paths } = useRoutes();
  return (
    <Box>
      <CarousselImage image="https://firebasestorage.googleapis.com/v0/b/digital-axon-87422.appspot.com/o/cover.jpeg?alt=media&token=1dad22df-3723-4253-8ece-78a29359e0c0">
        <Login variant="contained" onClick={() => navigate(paths.login.path)()}>
          Se connecter
        </Login>
        <AuthContainer title="">
          <Description
            title="Bienvenue sur le Site officiel du dahira hinlmou imamoul hamal"
            actionTitle="Se connecter"
            onClick={navigate(paths.login.path)}
          />
        </AuthContainer>
      </CarousselImage>
      <Footer />
    </Box>
  );
};

export default Home;
