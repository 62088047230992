import React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const feedbackMessages: { [key: string]: string } = {
  "auth/wrong-password": "Mot de pass incorrects",
  "auth/wrong-credentials": "Email ou mot de pass incorrects",
  "auth/email-already-in-use": "L'adresse email existe déjà",
  "auth/user-not-found": "L'adresse email n'existe pas",
  "auth/invalid-action-code": "Le code a expiré ou incorrect",
  "auth/cannot-reset-password": "Réinitialisation du mot de pass impossible",
  "auth/invalid-username": "Nom d'utilisateur incorrect",
  "report/submit-error": "Création ou Enregistrement du rapport impossible",
  "emargement/submit-error": "Création ou Validation impossible",
  "auth/change-profil": "Modification impossible",
  "auth/reset-password-success":
    "Un mail vous a été envoyé pour vous permettre de reinitaliser votre mot de passe. Merci de vérifier votre boite de reception.",
  "auth/account-exists-with-different-credential":
    "Ce compte existe avec un fournisseur different",
};

export type FeedBackType = "error" | "success" | "info";

const FeedBackContainer = styled(Paper)<{ type?: FeedBackType }>(
  ({ theme, type }) => ({
    color: "#fff",
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    backgroundColor:
      type === "error" ? theme.palette.error.main : theme.palette.success.main,
  })
);

export type FeedBackProps = {
  code?: string;
  message?: string;
  type?: FeedBackType;
};

export const FeedBack: React.FC<FeedBackProps> = ({
  code,
  message,
  type = "error",
}: FeedBackProps) => {
  return (
    <FeedBackContainer elevation={0} type={type}>
      {(code && feedbackMessages[code]) || message || "Erreur inconnue"}
    </FeedBackContainer>
  );
};

export default Error;
