import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { makeStyles, createStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import {
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useAuth, useMenuRoutes, useRoutes } from "hooks";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Menu, Route } from "routes";

const drawerWidth = 300;

const useStyles = makeStyles((theme: any) =>
  createStyles({
    drawerPaper: {
      width: drawerWidth,
    },
  })
);

type SidebarProps = {
  open: boolean;
  onClose: () => void;
};

const MenuItem: React.FC<{
  item: Route;
  subItems: Route[];
  onClick: (path: string) => void;
}> = ({ item, subItems, onClick }) => {
  const [open, setOpen] = useState(false);
  const hasItems = !!subItems.length;
  const handleOnClick = () => {
    if (hasItems) {
      setOpen((state) => !state);
    } else {
      onClick?.(item.path);
    }
  };
  return (
    <>
      <ListItemButton onClick={handleOnClick}>
        <ListItemText primary={item.label} />
        {!!subItems.length ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subItems.map((subItem) => (
            <ListItemButton
              sx={{ pl: 4 }}
              key={subItem.path}
              onClick={() => onClick(subItem.path)}
            >
              <ListItemText primary={subItem.label} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const Navigation: React.FC<SidebarProps> = ({
  open,
  onClose,
}: SidebarProps) => {
  const classes = useStyles();
  const container = window.document.body;
  const { navigate, paths } = useRoutes();
  const { isLoggedIn } = useAuth();
  const menus: Menu[] = useMenuRoutes();

  const handleOnClick = (path) => {
    onClose();
    navigate(path)();
  };

  return (
    <Drawer
      container={container}
      variant="temporary"
      anchor="left"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              handleOnClick(isLoggedIn ? paths.profil.path : paths.login.path)
            }
          >
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="Compte" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />

      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {menus.map((menu) => (
          <MenuItem
            key={menu.item.path}
            item={menu.item}
            subItems={menu.subItems}
            onClick={handleOnClick}
          />
        ))}
      </List>
      <Divider />
      <ListSubheader component="div" id="nested-list-subheader">
        Mode
      </ListSubheader>
      <ButtonGroup
        sx={{ width: "100%", boxSizing: "border-box", paddingX: 2 }}
        fullWidth
        variant="outlined"
        aria-label="outlined button group"
      >
        <Button>Clair</Button>
        <Button>Systeme</Button>
        <Button>Sombre</Button>
      </ButtonGroup>
    </Drawer>
  );
};

export default Navigation;
