import { UserRole } from "types";

export const ROLES = [
    {
        name: 'Administrateur',
        id: UserRole.administrator,
    },
    {
        name: 'Responsable de Section',
        id: UserRole.section
    }
]