import { useDocMutation, useDocQuery } from "hooks/queries";
import React from "react";
import { useParams } from "react-router-dom";
import Forms, { BaseFormsProps } from "./forms";

type Params = {
  id: string;
};

export const Edit: React.FC<BaseFormsProps> = (props) => {
  const params = useParams<Params>();
  const { data, isLoading } = useDocQuery<{ id; title }>(
    props.name,
    params.id!
  );
  const { mutateAsync: update } = useDocMutation(props.name, params.id!);
  const handleOnSubmit = (values) => {
    return update({ ...values, updated: new Date().getTime() });
  };
  if (isLoading) return null;
  return <Forms onSubmit={handleOnSubmit} {...props} initialValues={data} />;
};
