import React, { useLayoutEffect } from "react";
import * as Yup from "yup";
import { DynamicForm, AuthContainer, Layout } from "components";
import { useResetPassword, useRoutes } from "hooks";

export const Auth: React.FC = () => {
  const { paths, navigate, queryParams } = useRoutes();

  const { mode, oobCode } = queryParams;
  const { mutateAsync: resetPassword } = useResetPassword();

  const navigateToLogin = navigate(paths.login.path);
  const navigateToHome = navigate(paths.home.path);

  const handleOnSubmit = async ({ newPassword }) => {
    return resetPassword(
      { newPassword, oobCode },
      { onSuccess: navigateToLogin }
    );
  };

  useLayoutEffect(() => {
    if (mode !== "resetPassword" || !oobCode) {
      navigateToHome();
    }
  }, [mode, oobCode, navigateToHome]);

  return (
    <Layout>
      <AuthContainer title="Reinitialisation du mot de passe">
        <DynamicForm
          fields={{
            newPassword: {
              label: "Nouveau mot de passe",
              type: "password",
            },
            confirmNewPassword: {
              label: "Confirmation du mot de passe",
              type: "password",
            },
          }}
          validationSchema={Yup.object({
            newPassword: Yup.string()
              .min(6, "6 charactères minimum")
              .required("Ce champ est requis"),
            confirmNewPassword: Yup.string()
              .oneOf(
                [Yup.ref("newPassword")],
                "Les mots de pass ne se correspondent pas"
              )
              .required("Ce champ est required"),
          })}
          submitTitle="Valider"
          cancelTitle="Se connecter"
          onSubmit={handleOnSubmit}
          onCancel={navigateToLogin}
        />
      </AuthContainer>
    </Layout>
  );
};

export default Auth;
