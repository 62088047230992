import * as yup from "yup";
import { Crud } from "components";
import { countries } from "constants/countries";
import { regions } from "constants/regions";
import { useState } from "react";

export const SectionsCrud = () => {
  const [country, setCountry] = useState('SN');

  const isSenegal = country === 'SN';
  console.log('isSenegal', isSenegal); 

  let fields = {
    name: {
      label: "Section",
    },
    address: {
      label: 'Adresse'
    },
    country: {
      label: 'Pays',
      type: "select",
      options: countries.map(country => ({
        label: country.name,
        value: country.code
      }))
    },
  } as any

  if (isSenegal) {
    fields = {
      ...fields,
      region: {
        label: 'Région',
        type: "select",
        options: regions.map(region => ({
          label: region.name,
          value: region.code
        }))
      },
      departement: {
        label: 'Département',
      }

    }





  }

  const handleOnChange = ({ values }) => {
    if (values.country !== country) {
      setCountry(values.country);
    }

  }
  return (
    <Crud
      name="sections"
      initialValues={{
        name: "",
        address: "",
        country: "SN"
      }}
      fields={fields}
      validationSchema={yup.object().shape({
        name: yup.string().required("Ce champ est requis"),
        address: yup.string().required("Ce champ est requis"),
        country: yup.string().required("Ce champ est requis"),
      })}
      columns={[
        { field: "id", headerName: "ID", width: 200 },
        { field: "name", headerName: "Nom", width: 250 },
      ]}
      onChange={handleOnChange}
    />
  );
};

export default SectionsCrud;
