import { useMemo } from "react";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import { paths } from "../routes";

type QueryString = {
  [key: string]: any;
};

export const useRoutes = () => {
  const navigateRouter = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(() => {
    let params: { [key: string]: any } = {};
    const searchParams = new URLSearchParams(location.search);

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }, [location]);

  const navigate = (path: string) => () => {
    navigateRouter(path);
  };

  const goBack = () => {
    navigateRouter(-1);
  };

  const replace = (path: string) => () => {
    navigateRouter(path, { replace: true });
  };

  const setQueryParams = (queries: QueryString) => {
    // history.push({ search: `?${new URLSearchParams(queries).toString()}` });
  };

  const currentPath = useMemo(
    () => Object.values(paths).find(({ path }) => path === location.pathname),
    [location]
  );

  return {
    navigate,
    replace,
    paths,
    goBack,
    setQueryParams,
    queryParams,
    currentPath,
    useMatch,
  };
};

export const useMenuRoutes = () => {
  return [
    {
      item: paths.home,
      subItems: [],
    },
  ];
};
