import { User, UserRole } from "types";
import { useAuth } from "./auth";
import { useDocQuery } from "./queries";

export const useRoles = ()=>{
    const { user, loading, isLoggedIn } = useAuth();
    const { data: userInfo, isLoading } = useDocQuery<User>('administrators', user?.id ?? 'dd', { enabled: !!user?.id });

    return {
        user,
        isLoggedIn,
        isLoading: loading || isLoading, 
        role: userInfo?.role, 
        section: userInfo?.section,
        isAdmin: userInfo?.role === UserRole.administrator,
        isSection: userInfo?.role === UserRole.section
    }
}