import React from "react";
import Typography from "@mui/material/Typography";
import Copyright from "@mui/icons-material/CopyrightRounded";
import Link from "@mui/material/Link";
import { Box, Divider } from "@mui/material";
import { Layout } from "components/layout";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";

export const Footer = () => {
  return (
    <Box sx={(theme) => ({ backgroundColor: theme.palette.grey[100] })}>
      <Layout sx={{ paddingY: 3, display: "flex", flexDirection: {xs:"column", md: "row"}, alignItems:"center", textAlign:{xs:"center", md: "left"} }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6">Liens utiles</Typography>
          <Link
            href="https://www.facebook.com/hinlmou"
            color="inherit"
            underline="none"
          >
            Données personnelles et conditions générales
          </Link>
          <Link
            href="https://www.facebook.com/hinlmou"
            color="inherit"
            underline="none"
          >
            Confidentialité
          </Link>
          <Link
            href="https://www.facebook.com/hinlmou"
            color="inherit"
            underline="none"
          >
            Boutique
          </Link>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6">Assistance</Typography>
          <Link
            href="https://www.facebook.com/hinlmou"
            color="inherit"
            underline="none"
          >
            Contact
          </Link>
          <Link
            href="https://www.facebook.com/hinlmou"
            color="inherit"
            underline="none"
          >
            Accéder aux FAQ
          </Link>
        </Box>
      </Layout>
      <Layout sx={{ flexDirection: "column" }}>
        <Divider />
      </Layout>
      <Layout
        sx={{
          gap: 0,
          paddingY: 2,
          flexDirection: {xs:"column", md: "row"},
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="caption">Dieuredieuf yaye</Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Copyright scale="0.5" />{" "}
          <Typography variant="caption">
            {new Date().getFullYear()} Hinlmou, Tous droits réservés{" "}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Link href="https://www.facebook.com/hinlmou" color="inherit">
            <FacebookIcon />
          </Link>
          <Link
            href="https://www.instagram.com/hinlmou_imamoul_hamal"
            color="inherit"
          >
            <InstagramIcon />
          </Link>
          <Link href="https://www.youtube.com/c/HinlmouTv" color="inherit">
            <YoutubeIcon />
          </Link>
        </Box>
      </Layout>
    </Box>
  );
};

export default Footer;
