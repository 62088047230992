import React from "react";
import * as Yup from "yup";
import { DynamicForm, Layout, AuthContainer } from "components";
import { useRoutes, useAuth } from "hooks";
import { useCreateMutation } from "hooks/queries";
import { Message } from "types";

export const Contact = () => {
  const { paths, navigate } = useRoutes();
  const { user } = useAuth();
  const { mutateAsync: create } = useCreateMutation<Message>("messages");
  
  const navigateToHome = navigate(paths.home.path);

  const handleOnSubmit = React.useCallback(
    async (values: Message) => {
      try {
        await create(values);
      } catch (error) {
        console.log("error");
      }
    },
    [create]
  );
  return (
    <Layout>
      <AuthContainer title="Nous contacter">
        <DynamicForm
          initialValue={{
            firstName: user?.firstName ?? "",
            lastName: user?.lastName ?? "",
            email: user?.email ?? "",
            message: "",
          }}
          fields={{
            firstName: {
              label: "Prénom",
              type: "text",
            },
            lastName: {
              label: "Nom",
              type: "text",
            },
            email: {
              label: "Email",
              type: "text",
            },
            message: {
              label: "Message",
              type: "text",
              multiline: true,
              rows: 8,
            },
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required("ce champ est requis"),
            lastName: Yup.string().required("Ce champ est requis"),
            email: Yup.string()
              .email("Adresse email incorrect")
              .required("Ce champ est requis"),
            message: Yup.string().required("ce champ est requis"),
          })}
          submitTitle="Valider"
          cancelTitle="Aller à la page d'accueil"
          onSubmit={handleOnSubmit}
          onCancel={navigateToHome}
        />
      </AuthContainer>
    </Layout>
  );
};

export default Contact;
