import { nanoid } from 'nanoid'
import * as yup from "yup";
import { Crud } from "components";
import { ROLES } from "constants/roles";
import { useCollectionQuery, useForgetPassword, useRegister, useUpdateProfile } from "hooks";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { useState } from 'react';
import { Section, UserRole } from 'types';



export const UsersCrud = () => {
    const [role, setRole] = useState("");
    const { mutateAsync: createUser } = useRegister();
    const { mutateAsync: resetPassword } = useForgetPassword();
    const { mutateAsync: updateProfile } = useUpdateProfile();
    const { data: sections = [] } = useCollectionQuery<Section>('sections');

    let fields = {
        firstname: {
            label: "Prénom",
        },
        lastname: {
            label: "Nom",
        },
        email: {
            label: "Email",
        },
        role: {
            label: "Role",
            type: "select",
            options: ROLES.map(role => ({
                label: role.name,
                value: role.id
            }))
        },
    } as any

    if (role === UserRole.section.toString()) {
        fields['section'] = {
            label: "Section/Dahira",
            type: "select",
            options: sections.map((section) => ({
                label: section.name,
                value: section.id,
            })),
        }
    }

    const handleOnChange = ({ values }) => {
        if (values.role !== role) {
            setRole(values.role);
        }
    }

    const handleOnSubmit = async (values) => {
        try {
            const response = await createUser({
                email: values.email,
                password: nanoid(6)
            })
            Promise.all([
                updateProfile({
                    user: response.user,
                    displayName: values.firstname + " " + values.lastname
                }),
                setDoc(doc(getFirestore(), "administrators", response.user.uid), values),
                resetPassword({
                    email: values.email,
                })
            ])
        } catch (error) {
            console.log("error", error)
        }
    }

    return (
        <Crud
            name="administrators"
            initialValues={{
                firstname: "",
                lastname: "",
                email: ""
            }}
            fields={fields}
            validationSchema={yup.object().shape({
                firstname: yup.string().required("Ce champ est requis"),
                lastname: yup.string().required("Ce champ est requis"),
                email: yup.string().required("Ce champ est requis"),
                role: yup.string().required("Ce champ est requis"),
                section: role === UserRole.section.toString() ? yup.string().required("Ce champ est requis") : yup.string().optional()

            })}
            columns={[
                { field: "id", headerName: "ID", width: 200 },
                { field: "firstname", headerName: "Prénom", width: 250 },
                { field: "lastname", headerName: "lastname", width: 250 },
                { field: "email", headerName: "email" },
            ]}
            onChange={handleOnChange}
            onSubmit={handleOnSubmit}
        />
    );
};

export default UsersCrud;
