export type Route = {
  label: string;
  path: string;
};

export type Paths = {
  home: Route;
  login: Route;
  register: Route;
  forgetPassword: Route;
  auth: Route;
  profil: Route;
  changePassword: Route;
  admin: Route;
  users: Route;
  videos: Route;
  videoDetail: Route;
  addVideos: Route;
  watch: Route;
  listByCategory: Route;
  search: Route;
  contact: Route;
  messages: Route;
  subscribe: Route;
  subscription: Route;
};

export const paths = {
  home: {
    label: "Accueil",
    path: "/",
  },
  login: {
    label: "Login",
    path: "/login",
  },
  register: {
    label: "Register",
    path: "/register",
  },
  forgetPassword: {
    label: "Forget Password",
    path: "/forget-password",
  },
  auth: {
    label: "Auth",
    path: "/auth",
  },
  profil: {
    label: "Profil",
    path: "/profil",
  },
  changePassword: {
    label: "Password",
    path: "/profil/password",
  },
  admin: {
    label: "admin",
    path: "/admin",
  },
  users: {
    label: "administrateurs",
    path: "/admin/administrators",
  },
  members: {
    label: "Membres",
    path: "/admin/members",
  },
  sections: {
    label: "Sections",
    path: "/admin/sections",
  },
  
  messages: {
    label: "Messages",
    path: "/admin/messages",
  },
  
  contact: {
    label: "contact",
    path: "/contact",
  },
};

export type Menu = {
  item: Route;
  subItems: Route[];
};


