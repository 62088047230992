import React from "react";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useRoutes } from "hooks";

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 250,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

type SidebarItemType = {
  label: string;
  path: string;
  onClick: Function;
};

const SideBarItem = ({ label, path, onClick }: SidebarItemType) => {
  const { useMatch } = useRoutes();
  const selected = useMatch({
    path,
    //exact: true,
  });

  const handleOnClick = () => {
    onClick({ label, path });
  };

  return (
    <ListItem button selected={!!selected} onClick={handleOnClick}>
      <ListItemText primary={label} />
    </ListItem>
  );
};

export const Sidebar = () => {
  const classes = useStyles();
  const { paths, navigate } = useRoutes();

  const sidebarRoutes = [
    paths.profil,
    paths.changePassword,
  ];

  const handleOnSidebarItemOnClick = ({ path }) => {
    navigate(path)();
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {sidebarRoutes.map((route) => (
        <SideBarItem
          key={route.path}
          label={route.label}
          path={route.path}
          onClick={handleOnSidebarItemOnClick}
        />
      ))}
    </List>
  );
};

export default Sidebar;
