import React, { useState } from "react";
import * as Yup from "yup";
import {
  DynamicForm,
  Social,
  AuthContainer,
  Layout,
  FeedBackProps,
} from "components";
import { useAuth, useRegister, useRoutes } from "hooks";

export const Register: React.FC = () => {
  const { paths, navigate } = useRoutes();

  const navigateToLogin = navigate(paths.login.path);
  const navigateToInfos = navigate(paths.home.path);
  const { refetch } = useAuth();

  const { mutateAsync: register } = useRegister();
  const [error, setError] = useState<FeedBackProps>();

  const handleOnSubmit = async ({ email, password }) => {
    await register(
      { email, password },
      {
        onSuccess: () => {
          refetch();
          navigateToInfos();
        },
      }
    );
  };

  return (
    <Layout>
      <AuthContainer title="Inscription" >
        <DynamicForm
          header={<Social onSuccess={navigateToInfos} onError={setError} />}
          initialValue={{
            firstName: "MMMM",
            lastName: "SSSS",
            email: "ouloulou@yahoo.fr",
            password: "kadiallo",
            confirmPassword: "kadiallo",
          }}
          fields={{
            email: {
              label: "Email",
              type: "text",
            },
            password: {
              label: "Mot de passe",
              type: "password",
            },
            confirmPassword: {
              label: "Confirmation du mot de passe",
              type: "password",
            },
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .nullable()
              .email("Adresse email incorrect")
              .required("Ce champ est requis"),
            password: Yup.string()
              .nullable()
              .min(6, "6 charactères minimum")
              .required("Ce champ est requis"),
            confirmPassword: Yup.string()
              .nullable()
              .oneOf([
                Yup.ref("password"),
                "Les mots de pass ne se correspondent pas",
              ])
              .required("Ce champ est required"),
          })}
          feedBack={error}
          submitTitle="S'inscrire"
          cancelTitle="Se connecter"
          onSubmit={handleOnSubmit}
          onCancel={navigateToLogin}
        />
      </AuthContainer>
    </Layout>
  );
};

export default Register;
