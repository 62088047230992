
import { Routes, Route } from "react-router-dom";
import {
  Login,
  Register,
  ForgetPassword,
  Auth,
  Admin,
  Contact,
  Profil,
  ChangePassword,
  ChangeProfil,
  NotFound,
  Home,
} from "pages";

import { Loader, PrivateRoute } from "./components";
import { paths } from "./routes";

import { useAuth } from "./hooks/auth";
import { useDocQuery } from "hooks";
import { User } from "types";

const Root: React.FC = () => {
  const { user, loading, isLoggedIn } = useAuth();
  const { data, isLoading } = useDocQuery<User>('administrators', user?.id ?? 'dd', { enabled: !!user?.id });

  const isAdministrator = !!data?.role

  console.log('user', user);
  return (
    <Loader loading={loading || isLoading}>
      <Routes>
        <Route
          path={paths.login.path}
          element={
            <PrivateRoute required={isLoggedIn} navigateTo={paths.members.path}>
              <Login />
            </PrivateRoute>
          }
        />
        <Route path={paths.register.path} element={<Register />} />
        <Route
          path={paths.forgetPassword.path}
          element={
            <PrivateRoute required={isLoggedIn} navigateTo={paths.home.path}>
              <ForgetPassword />
            </PrivateRoute>
          }
        />
        <Route
          path={paths.auth.path}
          element={
            <PrivateRoute required={isLoggedIn} navigateTo={paths.home.path}>
              <Auth />
            </PrivateRoute>
          }
        />
        <Route path={paths.admin.path + "/*"} element={
          <PrivateRoute required={!isAdministrator} navigateTo={paths.home.path}>
            <Admin />
          </PrivateRoute>

        } />
        <Route path={paths.contact.path} element={<Contact />} />
        <Route path="/" element={
          <PrivateRoute required={isLoggedIn} navigateTo={paths.members.path}>
            <Home />
          </PrivateRoute>

        }>
          <Route path={paths.profil.path} element={<Profil />}>
            <Route
              path={paths.changePassword.path}
              element={<ChangePassword />}
            />
            <Route index element={<ChangeProfil />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Loader>
  );
};

export default Root;
