export interface Section {
    id: string;
    name: string;
    address: string;
}

export interface Member {
    id: string;
    gender: string;
    firstname: string;
    lastname: string;
    profession: string;
    identifiantNumber: string;
    section: string;
    address: string;
    isManager: boolean;
}

export enum Gender {
    man = "Homme",
    woman = "Femme", 
}
