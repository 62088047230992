import React, { useCallback } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Sidebar } from "../../components";
import { useRoutes } from "../../hooks/routes";
import Button from "@mui/material/Button";
import ArrowLeft from "@mui/icons-material/ChevronLeft";

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    flex: 1,
  },
  sidebar: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 250,
    },
    textAlign: "left",
  },
}));

export const Profil = () => {
  const classes = useStyles();
  const { currentPath, navigate, paths } = useRoutes();

  const navigateToHome = useCallback(() => {
    navigate(paths.home.path)();
  }, [navigate, paths]);
  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        <Button
          variant="text"
          startIcon={<ArrowLeft />}
          onClick={() => navigateToHome()}
        >
          Retour
        </Button>
        <Sidebar />
      </div>
      <div className={classes.content}>
        <Typography component="h2" variant="h4" align="center" gutterBottom>
          {currentPath?.label}
        </Typography>
        <Outlet />
      </div>
    </div>
  );
};
