import {
  createCheckoutSession,
  getCurrentUserSubscriptions,
  getStripePayments,
  getProducts,
  getProduct,
  Product,
  Session,
} from "@stripe/firestore-stripe-payments";
import axios from "axios";
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

export const getPayments = () => {
  const app = getApp();
  return getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers",
  });
};

export const getSubscription = async () => {
  const response = await getCurrentUserSubscriptions(getPayments());
  const currentSubscription = response.find((sub) =>
    sub.price.includes("price_1KZcScAQ52NOeMC8MHwG6TlE")
  );
  return currentSubscription;
};

export const subscribe = () => {
  return createCheckoutSession(getPayments(), {
    price: "price_1KZcScAQ52NOeMC8MHwG6TlE",
    client_reference_id: "cus_LG3kRqm8lbygRC",
    success_url: "http://localhost:3000",
  });
};

export const manageSubscription = async () => {
  const functions = getFunctions(getApp());
  const callFunc = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink"
  );
  const { data } = await callFunc({ returnUrl: window.location.origin });
  return data as Session;
};

export const authorize = () => {
  return axios.get(process.env.REACT_APP_WATCH_AUTHORIZE || "", {
    headers: {
      path: "/",
      domain: "hinlmou.com",
      origin: "http://dev.hinlmou.com'",
    },
  });
};


export const payWithPaydunya = () => {
  axios.post(
    "https://app.paydunya.com/sandbox-api/v1/checkout-invoice/create",
    {
      invoice: {
        total_amount: 5000,
        description: "Chaussure VANS dernier modèle",
      },
      store: { name: "Magasin le Choco" },
    },
    {
      headers: {
        "PAYDUNYA-MASTER-KEY": "df27NhCr-h9ie-krjA-Pz2l-t2GUPWXPe7YL",
        "PAYDUNYA-PRIVATE-KEY": "test_private_rMIdJM3PLLhLjyArx9tF3VURAF5",
        "PAYDUNYA-TOKEN": "IivOiOxGJuWhc5znlIiK",
      },
    }
  );
};
