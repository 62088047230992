export const regions = [
    {
        code: 'Dakar',
        name: 'Dakar'
    },
    {
        code: 'Diourbel',
        name: 'Diourbel'
    },
    {
        code: 'Fatick',
        name: 'Fatick'
    },
    {
        code: 'Kaffrine',
        name: 'Kaffrine'
    },
    {
        code: 'Kedougou',
        name: 'Kédougou'
    },
    {
        code: 'Kaolack',
        name: 'Kaolack'
    },
    {
        code: 'Kolda',
        name: 'Kolda'
    },
    {
        code: 'Louga',
        name: 'Louga'
    },
    {
        code: 'Matam',
        name: 'Matam'
    },
    {
        code: 'Saint-Louis',
        name: 'Saint Louis'
    },
    {
        code: 'Sedhiou',
        name: 'Sédhiou'
    },
    {
        code: 'Tambacounda',
        name: 'Tambacounda'
    },
    {
        code: 'Thies',
        name: 'Thiès'
    },
    {
        code: 'Ziguinchor',
        name: 'Ziguinchor'
    },
]